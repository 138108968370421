import cx from 'classnames';
import PropTypes from 'prop-types'

const HeaderSideNavItems = ({
  className: customClassName,
  children
}) => {

  const className = cx(
    {
      'side-nav__header-navigation': true
    },
    customClassName
  )

  return (
    <div className={className}>
      {children}
    </div>
  )
}

HeaderSideNavItems.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default HeaderSideNavItems

import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './App';
import LoginPage from './pages/LoginPage';
import apiClient from './utils/apiClient';


export default class AppHandler extends Component {

  token = localStorage.getItem('PADAPP_TK');
  permissions = localStorage.getItem('PADAPP_PR');
  userId = localStorage.getItem('PADAPP_UI');
  isAdmin = localStorage.getItem('PADAPP_AD');

  state = {
    isFetching: false,
    token: this.token ? this.token : false,
    permissions: this.permissions && this.permissions.indexOf(',') !== -1 ? this.permissions.split(',') : [],
    userId: this.userId ? this.userId : false,
    admin: this.isAdmin === 1 || this.isAdmin === '1' ? true: false 
  }




  authCallback = ({ token, permissions, userId, visualStructure, admin }) => {
    console.log(token, permissions, userId, visualStructure)
    const isAdmin = admin ? 1 : 0
    this.setState({
      token,
      permissions,
      userId,
      admin: admin
    });

    localStorage.setItem('PADAPP_TK', token);
    localStorage.setItem('PADAPP_PR', permissions.toString());
    localStorage.setItem('PADAPP_UI', userId.toString());
    localStorage.setItem('PADAPP_AD', isAdmin);

  }

  logoutCallback = async (logout = true) => {
    const _token = this.state.token

    this.setState({
      token: false,
      permissions: [],
      userId: false,
      isFetching: true,
      admin: false,
    });

    localStorage.removeItem('PADAPP_TK');
    localStorage.removeItem('PADAPP_PR');
    localStorage.removeItem('PADAPP_UI');
    localStorage.removeItem('PADAPP_AD');

    if (logout) {
      await apiClient('DELETE', process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION, '/auth/logout?token=' + _token);
    }

    this.setState({ isFetching: false });
    window.location = "/";

  };




  render() {
    return (

        <Router>
          <Switch>
            <Route path="/" render={(props) => this.state.token ? <App logoutCallback={this.logoutCallback} {...this.state} /> : <LoginPage authCallback={this.authCallback} />} />
          </Switch>
        </Router>

    )
  }
}

import React, { Component } from 'react'
import Content from '../components/ui-shell/Content'
import RowCategory from '../components/category/rowCategory'
import CarouselContainer from '../components/carousel/CarouselContainer'
import LazyLoad from 'react-lazyload';

export default class PhotosPage extends Component {

    render() {
        const { token, images, liga } = this.props
        console.log(images, 'las imagenes', liga)
        return (
            <Content className="content--videos">

                {images && images.data && images.data.map((row, i) => {
                    return (

                        <RowCategory key={i} index={i}>
                            <CarouselContainer liga={liga} token={token} type="images" id={row.key}{...row} />
                        </RowCategory>
                    )
                })}
            </Content>
        )
    }
}

import React, { Component } from "react";
import apiClient from "../utils/apiClient";
import Content from "../components/ui-shell/Content";
import WindowSpinner from "../components/WindowSpinner/WindowSpinner";
import Error from "../components/error/Error";
import ImageCard from "../components/ui-card/ImageCard";

export default class PhoneticPage extends Component {
  state = {
    isFetching: true,
    selectedTeam: false,
    teams: [],
    players: [],
    error: false,
    isPlaying: false,
  };

  getAudios = async () => {
    try {
      let qs = `?token=${this.props.token}`;
      if (this.state.selectedTeam) {
        qs += `&team=${this.state.selectedTeam}`;
      }
      const payload = await apiClient(
        "GET",
        process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION,
        "/audios" + qs
      );
      if (payload && payload.players) {
        const { selectedTeam, teams, players } = payload;
        this.setState({ selectedTeam, teams, players, isFetching: false });
      }
    } catch (err) {
      if (err.message === "Error: 403" || err.message === "403") {
        this.props.logoutCallback(false);
      } else {
        this.setState({
          error: "Service is temporarily unavailable, please try again later",
        });
      }
    }
  };

  componentDidMount() {
    this.getAudios();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedTeam !== false &&
      prevState.selectedTeam !== this.state.selectedTeam
    ) {
      this.getAudios();
    }
  }

  async playAudio(url) {
    if (!this.state.isPlaying) {
      await this.setState({ isPlaying: true });
      new Audio(url).play();
      setTimeout(async () => {
        await this.setState({ isPlaying: false });
      }, 1000);
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { isFetching, players, teams, error } = this.state;

    return (
      <Content className="content--search-result">
        <WindowSpinner background="#171717" visible={isFetching} />
        <div className="grid">
          <div className="page-header">
            <h1 className="page-header__title">Phonetic Guide</h1>
            <a
              className="btn btn--small"
              style={{ float: "right" }}
              href="https://static.laliganews.es/audios/phonetic.zip"
            >
              {" "}
              Download all audios{" "}
            </a>
            {teams && teams.length && (
              <div className="form-item">
                <div className="select select--light">
                  <label
                    htmlFor="selectedTeam"
                    className="label visually-hidden"
                  >
                    Select
                  </label>
                  <div className="select-input__wrapper">
                    <select
                      label="selectedTeam"
                      id="selectedTeam"
                      name="selectedTeam"
                      className="select-input"
                      value={this.state.selectedTeam}
                      onChange={this.handleChange}
                    >
                      {teams.map((team) => {
                        return (
                          <option key={team._id} value={team._id}>
                            {team.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
          {error && <Error message={error} />}

          {players && players.length ? (
            <div className="thumb-grid">
              {players.map((item, i) => {
                return (
                  <div className="thumb-grid__item" key={i}>
                    <div className="slide-card slide-card--video">
                      {item.location ? (
                        <a
                          onClick={() => this.playAudio(item.location)}
                          className="slide-card__trigger"
                        >
                          <div className="slide-card__thumbnail">
                            <img
                              className="slide-card__image"
                              src={item.thumbnail}
                              alt=""
                            />
                          </div>
                          <div className="slide-card__info">
                            <h5 className="slide-card__title">{item.title}</h5>
                          </div>
                        </a>
                      ) : (
                        <a className="slide-card__trigger">
                          <div className="slide-card__thumbnail">
                            <img
                              className="slide-card__image"
                              src={item.thumbnail}
                              alt=""
                            />
                          </div>
                          <div className="slide-card__info">
                            <h5 className="slide-card__title">{item.title}</h5>
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : isFetching ? null : (
            <Error />
          )}
        </div>
      </Content>
    );
  }
}

import React, { Component } from 'react'
import WindowSpinner from '../WindowSpinner/WindowSpinner'
import getVideo from '../../utils/getVideo'
import moment from 'moment'
import CategoryBadge from '../ui-card/CategoryBadge'
import Button from '../button/Button'
import Error from '../error/Error'
import apiClient from '../../utils/apiClient'
export default class DetailVideo extends Component {

    state = {
        isFetching: true,
        item: false,
        error: false,
        selectedTab: 0,
        manifest: false,
    }

    getVideo = async () => {
        try {
            const data = await getVideo(this.props.token, this.props.videoId)

            this.setState({ item: data, isFetching: false })

        } catch (err) {
            console.log(err)
            if (err.message === '403') {
                this.setState({ redirect: '/logout' })
            } else if (err.message === '401') {
                this.setState({ isFetching: false, error: "You don't currently have permission to access this content" })
            } else {
                this.setState({ isFetching: false, error: "Service is temporarily unavailable, please try again later" })
            }
        }
    }

    async componentDidMount() {
        if (this.props.admin) {
            this.setState({ selectedTab: 2 });
        }
        this.getVideo();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.videoId !== this.props.videoId) {
            this.getVideo();
        }
    }


    getManifest = async () => {
        try {
            if (this.state.item.category._id !== '5dee183b504b0e30acfc3dc0') {
                const resolution = this.state.item.category._id === '5c3374d78fa201d07294eea2' ? "hd" : "sd"
                const payload = await apiClient('GET', process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION, '/videos/' + this.props.videoId + '/play?token=' + this.props.token + '&resolution=' + resolution);
                if (payload && payload.manifest) {
                    this.setState({ manifest: payload.manifest });
                }

            }
        } catch (err) {
            console.log(err);
        }
    }


    download = async (type, item) => {
        const url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_VERSION}/videos/${item._id}/download/${type}?token=${this.props.token}`;
        console.log(url)

        window.open(url, '_blank').focus()
    }

    render() {
        const { isFetching, item, error, manifest, language } = this.state;
        return (
            <div className="grid">
                <WindowSpinner background="#171717" visible={isFetching} />

                {
                    error ? <Error message={error} /> :
                        item &&
                        <div className="detail-video">

                            <div className="detail-video__header">

                                <CategoryBadge text={item.category.name} className={`category-badge${item.category.color}`} />
                              

                                <span className="detail-video__date">
                                    {moment(item.publishAt).format('dddd DD/MM/YYYY')}  
                                    {language && language !== 'unknown' &&  item.language}
                                </span>
                               
                            </div>
                            <div className="detail-video__content">
                                {/* Video */}
                                <div className="video-player aspect-ratio aspect-ratio--16-9">
                                    {/* Player */}
                                    {manifest ?
                                        <video style={{maxWidth: "100%"}} src={this.state.manifest} autoPlay controls></video> :
                                        item.category._id === '5dee183b504b0e30acfc3dc0' ?
                                            <img src={process.env.REACT_APP_THUMBNAIL_URL + item.thumbnail} alt="poster" />
                                            :
                                            <div className="video-thumb" onClick={this.getManifest}>
                                                <img src={process.env.REACT_APP_THUMBNAIL_URL + item.thumbnail} alt="poster" />
                                                <img src="/img/play.png" className="playIcon" alt="play" />
                                            </div>

                                    }

                                    {/* Video Description */}
                                    <div className="video-player__description">
                                        <div className="content-switcher" role="tablist">
                                            <button
                                                type="button"
                                                role="tab"
                                                className="content-switcher-btn"
                                                onClick={() => this.setState({ selectedTab: 0 })}
                                            >
                                                <span className="content-switcher__label">Description</span>
                                            </button>
                                            <button
                                                type="button"
                                                role="tab"
                                                className="content-switcher-btn"
                                                onClick={() => this.setState({ selectedTab: 1 })}
                                            >

                                                <span className="content-switcher__label">Additional info</span>
                                            </button>
                                            {
                                                this.props.admin &&
                                                <button
                                                    type="button"
                                                    role="tab"
                                                    className="content-switcher-btn"
                                                    onClick={() => this.setState({ selectedTab: 2 })}
                                                >

                                                    <span className="content-switcher__label">Stats</span>
                                                </button>
                                            }

                                        </div>
                                        <article className="video-player__article">
                                            {
                                                this.state.selectedTab === 0 ? item.title :
                                                    this.state.selectedTab === 1 ? <p dangerouslySetInnerHTML={{ __html: item.description }}></p> :
                                                        <div>
                                                            <div className="rowf" style={{ justifyContent: "space-around" }}>
                                                                {
                                                                    item.stats && item.stats.all &&
                                                                    <div style={{ marginRight: "1rem" }} className="stats-card">
                                                                        <div className="stats-card-body">
                                                                            <div className="rowf">
                                                                                <div className="col">
                                                                                    <h5 className="text-muted">Total downloads</h5><div className="font-weight-bold">{item.stats.all.uniqueDownloads}</div>
                                                                                </div>
                                                                                <div className="col-auto col">
                                                                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                                                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    item.stats && item.stats.day &&
                                                                    <div style={{ marginRight: "1rem" }} className="stats-card">
                                                                        <div className="stats-card-body">
                                                                            <div className="rowf">
                                                                                <div className="col">
                                                                                    <h5 className="text-muted">24h Downloads</h5><div className="font-weight-bold">{item.stats.day.uniqueDownloads}</div>
                                                                                </div>
                                                                                <div className="col-auto col">
                                                                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24.000000pt" height="24.000000pt" viewBox="0 0 24.000000 24.000000" strokeLinecap="round" strokeLinejoin="round" fill="#fff" stroke="currentColor" preserveAspectRatio="xMidYMid meet">
                                                                                            <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)" >
                                                                                                <path d="M13 222 c-13 -14 -19 -175 -7 -206 5 -13 24 -16 99 -16 89 0 95 1 114 26 17 22 21 41 21 103 0 42 -4 82 -9 89 -12 19 -199 22 -218 4z m207 -97 c0 -58 -4 -77 -15 -81 -8 -4 -15 -12 -15 -20 0 -11 -19 -14 -85 -14 l-85 0 0 95 0 95 100 0 100 0 0 -75z" />
                                                                                                <path d="M105 143 c-14 -11 -16 -17 -7 -20 6 -3 12 -20 12 -40 0 -51 24 -37 28 17 4 51 -6 64 -33 43z" />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    item.stats && item.stats.week &&
                                                                    <div className="stats-card">
                                                                        <div className="stats-card-body">
                                                                            <div className="rowf">
                                                                                <div className="col">
                                                                                    <h5 className="text-muted">7d Downloads</h5><div className="font-weight-bold">{item.stats.week.uniqueDownloads}</div>
                                                                                </div>
                                                                                <div className="col-auto col">
                                                                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24.000000pt" height="24.000000pt" viewBox="0 0 24.000000 24.000000" strokeLinecap="round" strokeLinejoin="round" fill="#fff" stroke="currentColor" preserveAspectRatio="xMidYMid meet">
                                                                                            <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)" >
                                                                                                <path d="M13 222 c-13 -14 -19 -175 -7 -206 5 -13 24 -16 99 -16 89 0 95 1 114 26 17 22 21 41 21 103 0 42 -4 82 -9 89 -12 19 -199 22 -218 4z m207 -97 c0 -58 -4 -77 -15 -81 -8 -4 -15 -12 -15 -20 0 -11 -19 -14 -85 -14 l-85 0 0 95 0 95 100 0 100 0 0 -75z" />
                                                                                                <path d="M105 143 c-14 -11 -16 -17 -7 -20 6 -3 12 -20 12 -40 0 -51 24 -37 28 17 4 51 -6 64 -33 43z" />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                            </div>
                                                            <div className="rowf" style={{ justifyContent: "center", marginTop: "2rem", flexDirection: "column" }}>
                                                                <h4> Downloaded by: </h4>
                                                                <ul style={{fontSize: "1rem"}}>

                                                                    {
                                                                        item.stats && item.stats.all && item.stats.all.clients.map((client) => {
                                                                            return (
                                                                                    <li style={{fontSize: "1rem"}}>&nbsp;- {client}</li>

                                                                            )
                                                                        })
                                                                    }
                                                                </ul>

                                                            </div>
                                                        </div>

                                            }


                                        </article>
                                    </div>
                                </div>
                                {/* Aside */}
                                <aside className="video-aside">
                                    {/* Downloads */}
                                    <article className="video-info video-info--downloads">
                                        <header className="video-info__header">
                                            <h5 className="video-info__title">
                                                Downloads
                                            </h5>
                                            {
                                                this.props.admin &&
                                                <span className="downloads-counter">
                                                    <div className="downloads-counter__label">
                                                        {item.stats.uniqueDownloads}
                                                    </div>
                                                    <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                                </span>
                                            }

                                        </header>
                                        <section className="video-info__content">
                                            {/* HD Media */}
                                            {
                                                (item.videoHd || item.cleanVideoHd) &&
                                                <div className="download-block">
                                                    <h6 className="download-block__title">
                                                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path><circle cx="12" cy="13" r="4"></circle></svg>
                                                        All media HD
                                                    </h6>
                                                    {
                                                        item.videoHd &&
                                                        <Button variant="secondary"
                                                            onClick={() => this.download("videoHd", item)}
                                                            ghost>
                                                            {
                                                                item.category._id === '5c3374d78fa201d07294eea2' ? "Download 16:9" : "Download HD"
                                                            }
                                                        </Button>
                                                    }

                                                    &nbsp;
                                                    {
                                                        item.cleanVideoHd &&
                                                        <Button variant="secondary"
                                                            onClick={() => this.download("cleanVideoHd", item)}
                                                            ghost>
                                                            {
                                                                item.category._id === '5c3374d78fa201d07294eea2' ? "Download Clean 16:9" : "Download Clean HD"
                                                            }
                                                        </Button>
                                                    }
                                                </div>
                                            }

                                            {/* SD Media */}
                                            {
                                                (item.videoSd || item.cleanVideoSd) &&
                                                <div className="download-block">
                                                    <h6 className="download-block__title">
                                                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path><circle cx="12" cy="13" r="4"></circle></svg>
                                                        All media SD
                                                    </h6>
                                                    {
                                                        item.videoSd &&
                                                        <Button variant="secondary"
                                                            onClick={() => this.download("videoSd", item)}
                                                            ghost>
                                                            {
                                                                item.category._id === '5c3374d78fa201d07294eea2' ? "Download 9:16" : "Download SD"
                                                            }
                                                        </Button>
                                                    }
                                                    &nbsp;
                                                    {
                                                        item.cleanVideoSd &&
                                                        <Button variant="secondary"
                                                            onClick={() => this.download("cleanVideoSd", item)}
                                                            ghost>
                                                            {
                                                                item.category._id === '5c3374d78fa201d07294eea2' ? "Download Clean 9:16" : "Download Clean SD"
                                                            }
                                                        </Button>
                                                    }
                                                </div>
                                            }
                                            {
                                                (item.videoScript || item.videoScript2) &&
                                                <div className="download-block">
                                                    <h6 className="download-block__title">
                                                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path><circle cx="12" cy="13" r="4"></circle></svg>
                                                        Scripts
                                                    </h6>
                                                    {
                                                        item.videoScript &&
                                                        <Button variant="secondary"
                                                            onClick={() => this.download("videoScript", item)}
                                                            ghost>
                                                            Download Script {item.category._id === "5dee183b504b0e30acfc3dc0" ? "ENG" : ""}
                                                        </Button>
                                                    }

                                                    &nbsp;
                                                    {
                                                        item.videoScript2 &&
                                                        <Button variant="secondary"
                                                            onClick={() => this.download("videoScript2", item)}
                                                            ghost>
                                                            Download Script {item.category._id === "5dee183b504b0e30acfc3dc0" ? "ESP" : ""}
                                                        </Button>
                                                    }
                                                </div>
                                            }

                                        </section>
                                    </article>

                                    {/* Tags */}
                                    <article className="video-info video-info--tags">
                                        <header className="video-info__header">
                                            <h5 className="video-info__title">
                                                Tags
                                            </h5>
                                        </header>
                                        <section className="video-info__content">
                                            {
                                                item.tags && item.tags.length &&
                                                item.tags.map((tag, i) => {
                                                    return (
                                                        <span key={i} className="badge badge badge--dark">
                                                            {tag.name}
                                                        </span>
                                                    )

                                                })
                                            }
                                        </section>
                                    </article>
                                </aside>
                            </div>
                        </div>

                }
            </div>
        )
    }
}

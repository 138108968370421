import cx from 'classnames'
import PropTypes from 'prop-types'

const MarqueeContainer = ({
    title,
    children,
    className: customClassName,
}) => {

    const className = cx(`marquee_container`, customClassName)

    return (
        <div className={className}>
            <div className="grid">
                <div className="marquee_title">
                    {title}
                </div>
                {children}
            </div>
        </div>

    )
}

MarqueeContainer.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

export default MarqueeContainer

import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

const SideNavItems = ({
  className: customClassName,
  children,
}) => {

  const className = cx(
    'side-nav__items',
    customClassName
  )

  return (
    <ul className={className}>
      {children}
    </ul>
  )
}

SideNavItems.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isSideNavExpanded: PropTypes.bool
}

export default SideNavItems

import React, { Component } from "react";
import { Switch, Route, withRouter, Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import getLayout from "./utils/getLayout";
import WindowSpinner from "./components/WindowSpinner/WindowSpinner";
import HomePage from "./pages/HomePage";
import PhotosPage from "./pages/PhotosPage";

import DetailPage from "./pages/DetailPage";
import SearchResultPage from "./pages/SearchResultPage";
import MyAccountPage from "./pages/MyAccountPage";
import Header from "./components/ui-shell/Header";
import HeaderMenuButton from "./components/ui-shell/HeaderMenuButton";
import HeaderName from "./components/ui-shell/HeaderName";
import HeaderNavigation from "./components/ui-shell/HeaderNavigation";
import HeaderMenuItem from "./components/ui-shell/HeaderMenuItem";
import SideNav from "./components/ui-shell/SideNav";
import SideNavItems from "./components/ui-shell/SideNavItems";
import HeaderSideNavItems from "./components/ui-shell/HeaderSideNavItems";
import MarqueeContainer from "./components/marquee/MarqueeContainer";
import MarqueeItem from "./components/marquee/MarqueeItem";
import CategoryBadge from "./components/ui-card/CategoryBadge";
import Button from "./components/button/Button";
import Nav from "./components/Nav/Nav";
import LogoutPage from "./pages/LogoutPage";
import Error from "./components/error/Error";
import ExplorerPage from "./pages/ExplorerPage";
import SearchImagesPage from "./pages/SearchImagesPage";
import PhoneticPage from "./pages/PhoneticPage";
import QuickLinks from "./components/QuickLinks/QuickLinks";

class App extends Component {
  state = {
    isExpanded: false,
    search: {},
    videos: {},
    images: {},
    images2: {},
    recentlyAdded: "",
    visualStructure: [],
    isFetching: true,
    error: false,
  };

  getNewLayout = async () => {
    try {
      const { token } = this.props;
      const result = await getLayout(token, "layout");
      if (result && result._id) {
        const {
          videos,
          images,
          recentlyAdded,
          search,
          visualStructure,
          imagesl2,
        } = result;

        this.setState({
          videos,
          images,
          recentlyAdded,
          search,
          visualStructure,
          isFetching: false,
          error: false,
          imagesl2,
        });
      } else {
        this.setState({
          error: "Service is temporarily unavailable, please try again later",
        });
      }
    } catch (err) {
      console.log(err.message);
      if (err.message === "Error: 403" || err.message === "403") {
        this.props.logoutCallback(false);
      } else {
        this.setState({
          error: "Service is temporarily unavailable, please try again later",
        });
      }
    }
  };

  async componentDidMount() {
    this.getNewLayout();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.state.isActive) {
        this.setState({ isActive: false });
      }
      if (this.state.isExpanded) {
        this.setState({ isExpanded: false });
      }
    }
  }

  handleExpand = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  handleActive = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  outerClick = (ev) => {
    this.setState({ isExpanded: false });
  };

  errorClose = () => {
    window.location.reload();
  };

  render() {
    const {
      isFetching,
      recentlyAdded,
      videos,
      visualStructure,
      isExpanded,
      isActive,
      search,
      images,
      error,
      imagesl2,
    } = this.state;
    const userLang = navigator.language || navigator.userLanguage;

    if (error) {
      return (
        <div className="error-page">
          <Error message={this.state.error} closeCB={this.errorClose} />
        </div>
      );
    } else {
      return (
        <div className="App">
          {/* Spinner */}
          <WindowSpinner background="#171717" visible={isFetching} />

          {/*Header*/}
          <Header>
            <div className="grid">
              <HeaderMenuButton
                innerRef={this.innerRef}
                isActive={isExpanded}
                onClick={this.handleExpand}
              />
              <HeaderName href="/">
                <img src="/img/laliga-h.svg" /> &nbsp;{" "}
                <span style={{ fontSize: "2rem" }}>CONTENT DELIVERY</span>
              </HeaderName>
              <HeaderNavigation>
                {/*
     <HeaderMenuItem
                  href={
                    userLang === "es"
                      ? "/videos/LALIGA+CONTENT+HUB+V7+TX.transfer.mp4"
                      : "/videos/LALIGA+CONTENT+HUB+V7+ING.transfer.mp4"
                  }
                  external={true}
                >
                  Watch Tutorial
                </HeaderMenuItem>
                    */}

                <HeaderMenuItem href="/account">
                  My account
                  <svg
                    className="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                  </svg>
                </HeaderMenuItem>
                <HeaderMenuItem href="/logout">
                  Log out
                  <svg
                    className="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                </HeaderMenuItem>
              </HeaderNavigation>

              {/* Side Nav */}
              <SideNav expanded={isExpanded}>
                <SideNavItems>
                  <HeaderSideNavItems>
                    {/*
       <HeaderMenuItem
                      href={
                        userLang === "es"
                          ? "/videos/LALIGA+CONTENT+HUB+V7+TX.transfer.mp4"
                          : "/videos/LALIGA+CONTENT+HUB+V7+ING.transfer.mp4"
                      }
                      external={true}
                    >
                      View Tutorial
                    </HeaderMenuItem>
                        */}

                    <HeaderMenuItem href="/account">
                      My account
                      <svg
                        className="icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="12" cy="12" r="3"></circle>
                        <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                      </svg>
                    </HeaderMenuItem>
                    <HeaderMenuItem href="/logout">
                      Log out
                      <svg
                        className="icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>
                    </HeaderMenuItem>
                  </HeaderSideNavItems>
                </SideNavItems>
              </SideNav>
            </div>
          </Header>
  
          <QuickLinks />
          {/* Quick links */}
          {
            /*
          
          <div className={isActive ? "quick-links active" : "quick-links"}>
            <div className="grid">
              <h3 className="quick-links__title">
                <img style={{ maxWidth: "70px" }} src="/img/laliga-h-w.png" />{" "}
                quick links
              </h3>
              <div className="quick-links__block">
                <Link to="/" className="card">
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    LALIGA News
                    <br />
                    Content Hub
                  </h4>
                </Link>
                <a
                  href="https://broadcasters.mediapro.es/LALIGAFacilities/"
                  className="card"
                >
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    LALIGA
                    <br />
                    Accreditations
                  </h4>
                </a>
                <a href="https://sports.overon.es/" className="card">
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    LALIGA
                    <br />
                    Bookings
                  </h4>
                </a>
                <a
                  href="https://www.laliga.com/en-ES/laliga-santander/calendar"
                  className="card"
                >
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    LALIGA
                    <br />
                    Calendar
                  </h4>
                </a>
              </div>

              <h3 className="quick-links__title">
                <img style={{ maxWidth: "70px" }} src="/img/laliga-h-w.png" />{" "}
                corporate & Additional resources
              </h3>
              <div className="quick-links__block">
                <Link
                  to="/explorer/world-feed-elements-grapics-and-logos"
                  className="card"
                >
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    World feed elements
                    <br />
                    Graphic and logos
                  </h4>
                </Link>
                <Link
                  to="/explorer/broadcasts-obligation-elements"
                  className="card"
                >
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    Broadcasts
                    <br />
                    Obligation Elements
                  </h4>
                </Link>
                <Link to="/explorer/laliga-official-anthem" className="card">
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    LALIGA
                    <br />
                    Official Anthem
                  </h4>
                </Link>
                <Link to="/explorer/videoday-materials" className="card">
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    Videoday
                    <br />
                    Materials
                  </h4>
                </Link>
                <Link
                  to="/explorer/starter-pack-and-promotional-materials"
                  className="card"
                >
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    Starter Pack and
                    <br />
                    Promotional Materials
                  </h4>
                </Link>
                <Link to="/explorer/music-cue-sheet" className="card">
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    Music Cue
                    <br />
                    Sheets
                  </h4>
                </Link>
                <Link to="/photos/laliga-santander" className="card">
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    LALIGA EA Sports
                    <br />
                    Photos
                  </h4>
                </Link>
                <Link to="/photos/laliga-smartbank" className="card">
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    LALIGA Hypermotion
                    <br />
                    Photos
                  </h4>
                </Link>
                <Link to="/search?videos=last" className="card">
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    Today’s
                    <br />
                    Videos
                  </h4>
                </Link>

                <Link to="/phonetic" className="card">
                  <img
                    src="/img/logo.svg"
                    alt="Spinner"
                    className="card__icon"
                  />
                  <h4 className="card__title">
                    Phonetic
                    <br />
                    Guide
                  </h4>
                </Link>
              </div>
              <Button
                className="btn--icon-only quick-links__trigger"
                variant="secondary"
                size="small"
                onClick={this.handleActive}
              >
                <svg
                  className="btn__icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </Button>
            </div>
          </div>
        */}

          {/* Filter nav */}
          <Nav permissions={this.props.permissions} search={search} />

          {/* Marquee recently added */}
          {recentlyAdded && recentlyAdded.data ? (
            <MarqueeContainer title="Recently added...">
              <Marquee gradient={false}>
                {recentlyAdded.data.map((item) => {
                  return (
                    <MarqueeItem
                      key={item._id}
                      href={`/detail/video/${item._id}`}
                    >
                      <CategoryBadge
                        text={item.category.name}
                        className={`category-badge${item.category.color}`}
                      />
                      {item.title}
                    </MarqueeItem>
                  );
                })}
              </Marquee>
            </MarqueeContainer>
          ) : (
            ""
          )}

          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <HomePage
                  admin={this.props.admin}
                  permissions={this.props.permissions}
                  fetching={this.state.isFetching}
                  videos={videos}
                  visualStructure={visualStructure}
                  token={this.props.token}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/photos/laliga-santander"
              render={(props) => (
                <PhotosPage
                  admin={this.props.admin}
                  liga="l1"
                  permissions={this.props.permissions}
                  fetching={this.state.isFetching}
                  images={images}
                  token={this.props.token}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/photos/laliga-smartbank"
              render={(props) => (
                <PhotosPage
                  admin={this.props.admin}
                  liga="l2"
                  permissions={this.props.permissions}
                  fetching={this.state.isFetching}
                  images={imagesl2}
                  token={this.props.token}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/detail/video/:id"
              render={(props) => (
                <DetailPage
                  admin={this.props.admin}
                  permissions={this.props.permissions}
                  token={this.props.token}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/explorer/:path"
              render={(props) => (
                <ExplorerPage
                  admin={this.props.admin}
                  token={this.props.token}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/account"
              render={(props) => (
                <MyAccountPage
                  admin={this.props.admin}
                  getNewLayout={this.getNewLayout}
                  token={this.props.token}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/detail/:type/:id"
              render={(props) => (
                <DetailPage
                  admin={this.props.admin}
                  token={this.props.token}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/search"
              render={(props) => (
                <SearchResultPage
                  admin={this.props.admin}
                  permissions={this.props.permissions}
                  token={this.props.token}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/search/images"
              render={(props) => (
                <SearchImagesPage
                  admin={this.props.admin}
                  token={this.props.token}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/phonetic"
              render={(props) => (
                <PhoneticPage
                  admin={this.props.admin}
                  token={this.props.token}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path="/logout"
              render={(props) => (
                <LogoutPage logout={this.props.logoutCallback} />
              )}
            />
          </Switch>
        </div>
      );
    }
  }
}

export default withRouter(App);

import cx from 'classnames'
import PropTypes from 'prop-types'

const HeaderNavigation = ({
  children,
  className: customClassName
}) => {

  const className = cx(`header__nav`, customClassName)

  return (
    <nav className={className}>
      <ul className="header__menu-bar">
        {children}
      </ul>
    </nav>
  )
}

HeaderNavigation.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default HeaderNavigation

import cx from 'classnames';
import PropTypes from 'prop-types'

const Header = ({ className: customClassName, children }) => {

  const className = cx(`header`, customClassName)

  return (
    <header className={className}>
      {children}
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

export default Header

import cx from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const MarqueeItem = ({
    children,
    className: customClassName,
    href
}) => {

    const className = cx(`marquee_item`, customClassName)

    return (
        <Link to={href} className={className}>
            {children}
        </Link>
    )
}

MarqueeItem.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    href: PropTypes.string,
}

export default MarqueeItem

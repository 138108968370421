import cx from 'classnames'
import PropTypes from 'prop-types'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Carousel = ({
    className: customClassName,
    style = 'single',
    children,

}) => {
    const perRow = style === 'double' ? 4 :
        style === 'featured' ? 3 : 4;
    /*
    const [carousel, setCarousel] = useState({
        start: 0,
        limit: style === 'double' ? 8 :
            style === 'featured' ? 6 : 4,
        length: data.length
    })
*/
    const className = cx(`carousel carousel--${style}`, customClassName)

    const settings = {
        dots: false,
        infinite: false,
        rows: style === 'single' ? 1 : 2,
        slidesPerRow: perRow,
        speed: 500,
        centerPadding: 60,
        slidesToScroll: 1,
        lazyLoad: true,
        responsive: [
            {
                breakpoint: 1056,
                settings: {
                    rows: 1,
                    slidesPerRow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 672,
                settings: {
                    rows: 1,
                    slidesPerRow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 425,
                settings: {
                    rows: 1,
                    slidesPerRow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className={className}>
            <Slider   {...settings}>
                {children}
            </Slider>
        </div>
    )
}

Carousel.propTypes = {
    style: PropTypes.string,
    className: PropTypes.string,
}

export default Carousel

import React from 'react'
import { useParams } from 'react-router-dom'
import Content from '../components/ui-shell/Content'
import DetailVideo from '../components/DetailVideo/DetailVideo'

const DetailPage = ({
    token,
}) => {
    const { id } = useParams();


    return (
        <Content  className="content--videos content--videos-details">
            <DetailVideo token={token} videoId={id} />
        </Content>
    )
}


export default DetailPage

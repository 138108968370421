import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class Error extends Component {




  static propTypes = {
    message: PropTypes.string,
    closeCB: PropTypes.func
  };

  static defaultProps = {
    message: "We can't seem to find any clips that match your search",
    button: "GO BACK"
  };





  render() {
    return (
      <div className="box-error">
        <div className="box-error-container">
          <h3> {this.props.message}</h3>
          {
            !this.props.closeCB ?
            <Link to="/" className="btn btn--error"> {this.props.button} </Link> : 
            <button onClick={this.props.closeCB} className="btn"> TRY AGAIN </button>

          }
        </div>

      </div>

    )
  }


}
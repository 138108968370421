import apiClient from "./apiClient"


const updateSettings = async (token, key, body) => {
    try {
        const time = new Date().getTime()

        const data = await apiClient('PUT', process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION, '/users/' + key + '?token=' + token + '&time=' + time, body);
        return data;
    } catch (err) {
        throw new Error(err);
    }

}

export default updateSettings;
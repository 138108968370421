import cx from 'classnames'
import PropTypes from 'prop-types'

const CategoryBadge = ({
    children,
    text,
    className: customClassName,
}) => {

    const className = cx(`category-badge`, customClassName)

    return (
        <span className={className}>
            &nbsp;
            {text}
        </span>

    )
}

CategoryBadge.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
}

export default CategoryBadge

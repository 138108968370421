import React, { useState } from 'react';

function LoginForm({ Login, error }) {
    const [credentials, setCredentials] = useState({ userName: "", password: "", });

    const submitHandler = e => {
        e.preventDefault();
        Login(credentials);
    }

    return (
        <form className="form" onSubmit={submitHandler}>
                            <img className="laligaLogo" alt="laliga logo" src="/img/logo.svg" />
            {(error !== "") ? (<div className="box-error"> {error} </div>) : ""}

            <div className="form-group">
                <input type="text" name="text" id="userName" placeholder="Username" onChange={e => setCredentials({ ...credentials, userName: e.target.value })} value={credentials.userName} required />
            </div>
            <div className="form-group">
                <input type="password" name="password" id="password" placeholder="Password" onChange={e => setCredentials({ ...credentials, password: e.target.value })} value={credentials.password} required />
            </div>
            <div className="form-group">
                <button type="submit"> Sign In </button>
            </div>
        </form>
    )
}

export default LoginForm;

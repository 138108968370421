import React, { Component } from 'react'
import Account from '../components/my-account/Account'
import Notifications from '../components/my-account/Notifications'
import Visual from '../components/my-account/Visual'
import Content from '../components/ui-shell/Content'
import { Link } from 'react-router-dom'

export default class MyAccountPage extends Component {
    state = {
        tabs: ['account', 'notifications', 'visual'],
        selectedTab: 2,
    }
    componentDidMount() {
        console.log(this.props)

    }
    
    render() {
        const { selectedTab } = this.state
        const { token } = this.props
        console.log(selectedTab)
        return (
            <Content className="content--myaccount settings">
                <div className="grid grid--sm">
                    <Link to="/" className="modal-close" aria-label="Close">
                        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="modal-close__icon"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
                    </Link>
                    <div className="settings__header">
                        <Link to="/" className="settings__close">
                        <img src="/img/logo.svg" alt="LaLiga" className="settings__logo" />
                        </Link>
                    </div>
                    <div className="settings__content">
                        <div className="content-switcher" role="tablist">
                            {
                                /*
                            <button
                                type="button"
                                role="tab"
                                    className={selectedTab === 0 ? "content-switcher-btn content-switcher--selected" : "content-switcher-btn"}
                                onClick={() => this.setState({ selectedTab: 0 })}
                            >
                                    <span className="content-switcher__label">Account</span>
                            </button>
                                */
                            }

                            <button
                                type="button"
                                role="tab"
                                className={selectedTab === 1 ? "content-switcher-btn content-switcher--selected" : "content-switcher-btn"}
                                onClick={() => this.setState({ selectedTab: 1 })}
                            >
                                <span className="content-switcher__label">Notifications</span>
                            </button>
                            <button
                                type="button"
                                role="tab"
                                className={selectedTab === 2 ? "content-switcher-btn content-switcher--selected" : "content-switcher-btn"}
                                onClick={() => this.setState({ selectedTab: 2 })}
                            >
                                <span className="content-switcher__label">Visual Structure</span>
                            </button>
                        </div>
                        <article className="settings__article">
                            {
                                selectedTab === 2 ? <Visual getNewLayout={this.props.getNewLayout} token={token} /> : selectedTab === 1 ? <Notifications token={token} /> : <Account token={token} />

                            }
                        </article>
                    </div>
                </div>
            </Content>
        )
    }
}

import React, { Component } from 'react'
import apiClient from '../utils/apiClient'
import Content from '../components/ui-shell/Content'
import WindowSpinner from '../components/WindowSpinner/WindowSpinner'
import VideoCard from '../components/ui-card/VideoCard'
import Button from '../components/button/Button'
import Error from '../components/error/Error'
export default class SearchResultPage extends Component {
    state = {
        isFetching: true,
        data: [],
        page: 0,
        pages: 0,
        error: false
    }

    async componentDidMount() {
        try {
            this.getVideos()
        } catch (err) {
            console.log(err)
        }
    }

    getVideos = async (nextPage = false) => {
        try {
            const search = this.props.location.search
            const Page = this.state.page + 1
            if (search) {
                const apiPath = !nextPage ? '/videos' + search + '&token=' + this.props.token : '/videos' + search + '&token=' + this.props.token + '&page=' + Page
                const payload = await apiClient('GET', process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION, apiPath)
                if (nextPage) {
                    let videos = this.state.data
                    return this.setState({ data: videos.concat(payload.data), isFetching: false, page: payload.page, pages: payload.pages, stats: payload.stats })
                } else {
                    return this.setState({ ...payload, isFetching: false })
                }
            }

        } catch (err) {
            if (err.message === 'Error: 403' || err.message === '403') {
                this.props.logoutCallback(false);
            } else {
                this.setState({ error: "Service is temporarily unavailable, please try again later" });
            }
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({ isFetching: true });
            this.getVideos();
        }
    }

    render() {
        const { isFetching, data, error, stats } = this.state
        const { token, admin, permissions } = this.props

        return (
            <Content className="content--search-result">
                <WindowSpinner background="#171717" visible={isFetching} />
                <div className="grid">
                    <div className="page-header">
                        <h1 className="page-header__title">Search Results</h1>
                    </div>
                    {
                        error && <Error message={error} />
                    }
                    {
                        data && data.length ?
                            <div className="thumb-grid">
                                {
                                    data.map((item, i) => {

                                        return (
                                            permissions.includes(item.category._id) ?

                                                <div className="thumb-grid__item" key={i}>
                                                    <VideoCard stats={stats} admin={admin} type={"video"} token={token} key={item._id} {...item} />
                                                </div> : null
                                        )
                                    })
                                }
                            </div> : isFetching ? null : <Error />
                    }

                    {
                        this.state.page < this.state.pages &&
                        <div className="load-more">
                            <Button
                                variant="secondary"
                                size="field"
                                onClick={e => this.getVideos(true)}
                            >
                                View More
                            </Button>
                        </div>
                    }
                </div>
            </Content>
        )
    }
}

import React, { Component } from 'react'
import Content from '../components/ui-shell/Content'
import PropTypes from 'prop-types'
import RowCategory from '../components/category/rowCategory'
import CarouselContainer from '../components/carousel/CarouselContainer'
import arrayMove from '../utils/utils'
import LazyLoad from 'react-lazyload';


class HomePage extends Component {

    state = {
        layout: []
    }

    parseLayout = () => {
        let { videos, visualStructure } = this.props
        if (videos.data) {
            let layout = videos.data
            for (const [indexNew, key] of visualStructure.entries()) {
                const indexOld = layout.findIndex(x => x.key === key)
                layout = arrayMove(layout, indexOld, indexNew + 3)
                console.log(indexOld, indexNew + 3);
            }
            console.log(layout)
            this.setState({ layout: layout })
        }
    }

    componentDidMount() {
        this.parseLayout()

    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.videos !== this.props.videos || prevProps.visualStructure !== this.props.visualStructure) {
            this.parseLayout()
        }


    }




    render() {
        const { token, permissions, admin } = this.props

        const { layout } = this.state
        
        return (
            <Content className="content--videos">

                {layout && layout.map((row, i) => {
                    return (
                        permissions.includes(row.key) || row.key === 69 ?

                        <RowCategory key={i} index={i}>
                            <LazyLoad height={200}>
                                <CarouselContainer admin={admin} permissions={permissions} token={token} type="videos" id={row.key}{...row} />
                            </LazyLoad>
                        </RowCategory> : null
                    )
                })}
            </Content>
        )
    }
}

HomePage.propTypes = {
    permissions: PropTypes.array.isRequired,
    videos: PropTypes.object.isRequired,
    visualStructure: PropTypes.array.isRequired,
}

export default HomePage

import cx from 'classnames'
import PropTypes from 'prop-types'
import TitleCategory from '../category/titleCategory'
import VideoCard from '../ui-card/VideoCard'
import ImageCard from '../ui-card/ImageCard'

import Carousel from './Carousel'
import PortraitCard from '../ui-card/PortraitCard'

const CarouselContainer = ({
    className: customClassName,
    type,
    background,
    token = false,
    admin = false,
    stats = false,
    permissions,
    data = [],

    ...rest
}) => {
    console.log(data);
    const className = cx(`carousel-container`, customClassName)
    return (
        <div className={className} style={{ backgroundImage: background }}>

            {
                type === 'images' ? <TitleCategory href={`/search/images?type=${type}&journey=${rest.journey}&liga=${rest.liga}`} title={rest.journey} /> :
                    rest.id && rest.id !== 69 ? <TitleCategory title={rest.name} href={`/search?type=${type}&category=${rest.id}`} /> : <TitleCategory title={rest.name} />
            }
            <Carousel style={rest.style}>
                {
                    data.map((item, i) => {
                        return (
                            rest.style === 'portrait' && (!item.thumbnail || !item.video) ? null :
                            item.category && item.category._id && permissions.includes(item.category._id) ? 
                                <div key={i}>
                                    {
                                        type === 'images' ?
                                            <ImageCard type={type} token={token}  {...item} /> : item.category && item.category._id && permissions.includes(item.category._id) ? rest.style === 'portrait' ?
                                                <PortraitCard type={type} token={token} key={item._id} {...item} /> : <VideoCard stats={stats} admin={admin} type={type} token={token} key={item._id} {...item} /> : null
                                    }

                                </div> : type === 'images' ?<div key={i}> <ImageCard type={type} token={token}  {...item} />  </div>  : null
                        )
                    })
                }
            </Carousel>

        </div>
    )
}

CarouselContainer.propTypes = {
    background: PropTypes.string,
    className: PropTypes.string,
    data: PropTypes.array,
    type: PropTypes.string
}

export default CarouselContainer

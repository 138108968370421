import cx from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const HeaderMenuItem = ({
  children,
  className,
  external = false,
  href,
  isCurrentPage
}) => {

  const linkClassName = cx({
    'header__menu-item': true,
    'header__menu-item--current': isCurrentPage,
  })
  return (
    <li className={className}>
      {
        external ? <a href={href} target="_blank" className={linkClassName}>
          <span className="text-truncate--end">
            {children}
          </span>
        </a> :
          <Link to={href} className={linkClassName}>
            <span className="text-truncate--end">
              {children}
            </span>
          </Link>
      }

    </li>
  )
}

HeaderMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isCurrentPage: PropTypes.bool,
  href: PropTypes.string,
  external: PropTypes.bool,
}

export default HeaderMenuItem

import React, { useState } from 'react';
import md5 from 'md5';

import LoginForm from '../components/forms/LoginForm';
import apiClient from '../utils/apiClient';
import VideoBackground from '../components/VideoBackground/VideoBackground';

 const LoginPage = ({ authCallback }) => {

    const [error, setError] = useState("")

    const Login = async user => {
        if (user.userName === '' || user.password === '') {
            return setError("Please complete all the fields of the form")
        }
        try {
            console.log(user)
            const response = await apiClient('POST', process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION, '/auth/login', { userName: user.userName, password: md5(user.password) });

            if (response && response.token) {
                authCallback(response);
            }
        } catch (err) {
            console.log(err);
            return setError("You have entered an invalid username or password");


        }
    }

    return (
        <div className="login">
            <LoginForm Login={Login} error={error} />
        </div>

    )
}

export default LoginPage;


import cx from 'classnames'
import PropTypes from 'prop-types'

const Content = ({
  className: customClassName,
  children,
}) => {

  const className = cx('content', customClassName)

  return (
    <main className={className}>
      {children}
    </main>
  )
}

Content.propTypes = {
  className: PropTypes.string
}

export default Content

import cx from 'classnames'
import PropTypes from 'prop-types'

const Button = ({
	className: customClassName,
	children,
	disabled,
	ghost,
	href,
	id,
	onClick,
	variant,
	size,
	iconType = false,
	icon = false,
	dataIndex = false,
}) => {

	const className = cx({
		'btn': true,
		'btn--disabled': disabled,
		'btn--ghost': ghost,
		[`btn--${variant}`]: variant,
		'btn--field': size === 'field',
		'btn--small': size === 'small',
		'btn--x-small': size === 'x-small',
		[customClassName]: !!customClassName,
	})

	return (
		href
			?
			<a className={className} href={href}>
				<span className="btn__label">
					{children}
				</span>
			</a>
			:
			<button
				data-index={dataIndex}
				className={className}
				onClick={!id ? onClick : () => onClick(id)}
			>
				<span className="btn__label">
					{children}
				</span>
				{iconType === 'campana' ? icon === true ? <svg className="btn__icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg> : <svg className="btn__icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M13.73 21a2 2 0 0 1-3.46 0"></path><path d="M18.63 13A17.89 17.89 0 0 1 18 8"></path><path d="M6.26 6.26A5.86 5.86 0 0 0 6 8c0 7-3 9-3 9h14"></path><path d="M18 8a6 6 0 0 0-9.33-5"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg> : false} 
			</button>
	)
}

Button.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	disabled: PropTypes.bool,
	ghost: PropTypes.bool,
	href: PropTypes.string,
	id: PropTypes.number,
	onClick: PropTypes.func,
	variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'link']).isRequired,
	size: PropTypes.oneOf(['default', 'field', 'small', 'x-small'])
}

Button.defaultProps = {
	disabled: false,
	variant: 'primary',
	size: 'default'
}

export default Button
import cx from 'classnames'
import PropTypes from 'prop-types'

const SideNav = ({
  expanded,
  className: customClassName,
  children
}) => {

  const className = cx({
    'side-nav': true,
    'side-nav--ux': true,
    'side-nav--expanded': expanded,
    'side-nav--collapsed': !expanded,
    [customClassName]: !!customClassName
  })

  const overlayClassName = cx({
    'side-nav__overlay': true,
    'side-nav__overlay--active': expanded,
  })

  return (
    <>
      <div className={overlayClassName} />
      <nav
        aria-hidden={!expanded}
        className={`side-nav__navigation ${className}`}
      >
        {children}
      </nav>
    </>
  )
}

SideNav.propTypes = {
  className: PropTypes.string,
  expanded: PropTypes.bool,
}

export default SideNav

import cx from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
const TitleCategory = ({
    title,
    className: customClassName,
    href,
    ...rest
}) => {

    const className = cx(`row-category__header`, customClassName)
    return (
        <div className={className}>
            <h1 className="row-category__title">{title}</h1>
            {href && <Link className="row-category__view-all" to={href}>
                View all media
                <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
            </Link>}
        </div>
    )
}

TitleCategory.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    href: PropTypes.string
}

export default TitleCategory

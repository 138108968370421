import apiClient from "./apiClient"


const getVideo = async(token, key) => {
    try {   

        const data = await apiClient('GET', process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION, '/videos/' + key + '?token=' + token);
        return data;
    }catch(err){
        throw new Error(err);
    }

}

export default getVideo;
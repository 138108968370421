import React, { useState } from 'react'
import cx from 'classnames'
import moment from 'moment'


const ImageCard = ({
    location,
    createdAt,
    title,
    className: customClassName,
    thumbnail,
    journey,
}) => {
    const [modal, setModal] = useState(false)





    const className = cx(customClassName, {
        'slide-card': true,
        'slide-card--video': true
    })
    const date = moment(createdAt).format('dddd DD/MM/YYYY')
    return (
        <div className={className}>

            <a href={location} target="_blank" className="slide-card__trigger">

                <div className="slide-card__thumbnail">

                    <div className="container-16-9">

                            <img className="slide-card__image" src={thumbnail} alt="" />
                    </div>


                </div>
                <div className="slide-card__info">
                    <h5 className="slide-card__date">
                        {date}
                    </h5>
                    <h5 className="slide-card__title">
                        {title}
                    </h5>
                </div>
            </a>


        </div>
    )
}


export default ImageCard

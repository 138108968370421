import cx from 'classnames'
import PropTypes from 'prop-types'

const HeaderMenuButton = ({
  className: customClassName,
  isActive,
  isCollapsible,
  onClick,
  innerRef
}) => {

  const className = cx({
    [customClassName]: !!customClassName,
    'header__action': true,
    'header__menu-trigger': true,
    'header__action--active': isActive,
    'header__menu-toggle': true,
    'header__menu-toggle__hidden': !isCollapsible,
  })

  return (
    <button
      ref={innerRef}
      className={className}
      type="button"
      onClick={onClick}
    >
      {isActive ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>}
    </button>
  )
}

HeaderMenuButton.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  onClick: PropTypes.func,
  innerRef: PropTypes.object
}

export default HeaderMenuButton

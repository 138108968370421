import React, { useState, useEffect } from "react";
import { Portal } from "react-portal";
import cx from "classnames";
import PropTypes from "prop-types";
import CategoryBadge from "./CategoryBadge";
import moment from "moment";
import Modal from "../modal/Modal";
import DetailVideo from "../DetailVideo/DetailVideo";

const VideoCard = ({
  _id,
  title,
  thumbnail,
  category,
  publishAt,
  token = false,
  admin = false,
  stats = false,
  language = false,
  episode = 0,
  className: customClassName,
}) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      document.body.classList.remove("body--with-modal-open");
    };
  }, []);

  const closeModal = (e) => {
    e.preventDefault();
    setModal(false);
    document.body.classList.remove("body--with-modal-open");
  };

  const openModal = async (e) => {
    e.preventDefault();
    setModal(true);
    document.body.classList.add("body--with-modal-open");
  };

  const className = cx(customClassName, {
    "slide-card": true,
    "slide-card--video": true,
  });

  const date = moment(publishAt).format("dddd DD/MM/YYYY");
  return (
    <div className={className}>
      <a onClick={openModal} className="slide-card__trigger">
        <div className="slide-card__thumbnail">
          <div className="container-16-9">
            <img
              className="slide-card__image"
              src={process.env.REACT_APP_THUMBNAIL_URL + thumbnail}
              alt=""
            />
          </div>
          {language && language !== "unknown" && (
            <CategoryBadge
              text={language}
              className={`category-badge${category.color} category-badge--absolute category-badge--language`}
            />
          )}
          {episode && episode > 0 && (
            <CategoryBadge
              text={'Episode: ' + episode}
              className={`category-badge${category.color} category-badge--absolute category-badge--episode`}
            />
          )}
          <CategoryBadge
            text={category.name}
            className={`category-badge${category.color} category-badge--absolute`}
          />

          <CategoryBadge
            text={category.name}
            className={`category-badge${category.color} category-badge--absolute`}
          />
        </div>
        <div className="slide-card__info">
          {admin && stats ? (
            <span
              className="downloads-counter"
              style={{ position: "absolute", right: 0, width: "auto" }}
            >
              <div className="downloads-counter__label">
                {stats[_id] ? stats[_id] : 0}
              </div>
              <svg
                className="icon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="7 10 12 15 17 10"></polyline>
                <line x1="12" y1="15" x2="12" y2="3"></line>
              </svg>
            </span>
          ) : null}

          <h5 className="slide-card__date">{date}</h5>
          <h5 className="slide-card__title">{title}</h5>
        </div>
      </a>
      {modal && (
        <Portal>
          <Modal className="modal--video" closeModal={closeModal}>
            <DetailVideo admin={admin} token={token} videoId={_id} />
          </Modal>
        </Portal>
      )}
    </div>
  );
};

VideoCard.propTypes = {
  _id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  category: PropTypes.object.isRequired,
  publishAt: PropTypes.string.isRequired,
};

export default VideoCard;

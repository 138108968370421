import React, { Component } from 'react'
import WindowSpinner from '../components/WindowSpinner/WindowSpinner'
export default class LogoutPage extends Component {
    componentDidMount() {
        this.props.logout();
    }
    
    render() {
        return (
            <WindowSpinner background="#171717" visible={true} />
        )
    }
}

import React, { Component } from 'react'

import { Link, withRouter, Redirect } from 'react-router-dom'
import Button from '../button/Button'

class Nav extends Component {
    state = {
        search: '',
        category: '',
        journey: '',
        teams: '',
        categoryIndex: false,
        qs: false
    }

    handleChange = (event) => {

        this.setState({
            [event.target.name]: event.target.value
        })

    }


    handleSubmit = (event) => {
        event.preventDefault()
        const keys = ['search', 'category', 'teams', 'journey']
        let qs = false
        for (const key of keys) {
            if (!qs) {
                if (this.state[key] && this.state[key] !== '' && this.state[key] !== 'All Categories' && this.state[key] !== 'Filter by matchday') {
                    qs = `?${key}=${this.state[key]}`
                }
            } else {
                if (this.state[key] && this.state[key] !== '' && this.state[key] !== 'All Categories' && this.state[key] !== 'Filter by matchday') {
                    qs += `&${key}=${this.state[key]}`
                }
            }
        }

        if (!qs) {
            return this.setState({ qs: '' })
        }
        return this.setState({ qs: qs })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname === '/' && prevProps.location.pathname !== '/') {
            this.setState({
                qs: false,
                search: '',
                category: '',
                journey: '',
                teams: '',
            })

        }
        if (this.state.qs && !prevState.qs) {

            this.setState({ qs: false })
        }
    }

    render() {
        const { search, permissions } = this.props

        return (
            <div className="filter-nav">
                <div className="grid">
                    <div className="filter-nav__header">
                        <Link to="/" >
                        <img src="/img/logo.svg" alt="LaLiga" className="filter-nav__logo" />

                        </Link>

                        <ul className="teams-nav">
                            {search && search.teams && search.teams.map((team, i) => {
                                return (
                                    <li key={i} className="teams-nav__item">
                                        <Link to={`/search?type=videos&teams=${team._id}`} className="teams-nav__trigger">
                                            <img src={team.icon} alt={team.name} />
                                        </Link>
                                    </li>
                                )
                            })
                            }

                        </ul>
                    </div>
                    <form className="filter-nav__searchbox" onSubmit={this.handleSubmit}>
                        <div className="form-item text-input-wrapper">
                            <div className="text-input__field-wrapper">
                                <input id="search" name="search" placeholder="Type search..." type="text" className="text-input" title="Type search..." onChange={this.handleChange} value={this.state.search} />
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="select select--light">
                                <label htmlFor="categories" className="label visually-hidden">Select</label>
                                <div className="select-input__wrapper">
                                    <select label="Categories" id="category" name="category" className="select-input" value={this.state.category} onChange={this.handleChange}>
                                        <option value="" defaultValue>All categories</option>
                                        {
                                            search.categories && search.categories.map((category, ind) => {
                                                return (
                                                    permissions.includes(category._id) ? <option key={category._id} data-categoryindex={ind} value={category._id} >{category.name}</option> : null
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="select">
                                <label htmlFor="categories" className="label visually-hidden">Select</label>
                                <div className="select-input__wrapper">
                                    <select label="Matchdays" id="journey" name="journey" className="select-input" value={this.state.journey} onChange={this.handleChange}>
                                        <option value="" defaultValue>Filter by matchday</option>
                                        {
                                            search.journeys && search.journeys.map((journey, i) => {
                                                return (
                                                    <option key={journey._id} value={journey._id} style={journey.name === 'N/A' ? { display: "none" } : null}>{journey.name}</option>

                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="form__submit">
                            <Button type="submit" variant="secondary">Search</Button>
                        </div>
                    </form>
                </div>
                {this.state.qs &&
                    <Redirect to={"/search" + this.state.qs} />
                }
            </div>
        )
    }
}


export default withRouter(Nav)
import apiClient from "./apiClient"


const getSettings = async (token, key) => {
    try {
        const time = new Date().getTime()

        const data = await apiClient('GET', process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION, '/users/' + key + '?token=' + token + '&time=' + time);
        return data;
    } catch (err) {
        throw new Error(err);
    }

}

export default getSettings;
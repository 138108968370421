import React, { Component } from 'react'
import getSettings from '../../utils/getSettings'
import WindowSpinner from '../WindowSpinner/WindowSpinner'
import updateSettings from '../../utils/updateSettings'
import { Redirect } from 'react-router-dom'
import arrayMove from '../../utils/utils'

export default class Visual extends Component {

    state = {
        error: false,
        categories: [],
        category0: "",
        category1: "",
        category2: "",
        userStructure: [],
        isFetching: true,
        success: false
    }

    async componentDidMount() {
        try {
            const visualStructure = await getSettings(this.props.token, 'visual')
            const category0 = visualStructure.userStructure && visualStructure.userStructure[0] ? visualStructure.userStructure[0] : ""
            const category1 = visualStructure.userStructure && visualStructure.userStructure[1] ? visualStructure.userStructure[1] : ""
            const category2 = visualStructure.userStructure && visualStructure.userStructure[2] ? visualStructure.userStructure[2] : ""
            await this.setState({ isFetching: false, categories: visualStructure.categories, category0, category1, category2, userStructure: visualStructure.userStructure })
        } catch (err) {
            this.setState({ error: "Service is temporarily unavailable, please try again later" })
        }
    }




    submitHandler = async (e) => {
        e.preventDefault()
        try {
            this.setState({ error: false, success: false });

            const { category0, category1, category2 } = this.state;
            const { token } = this.props;

            const data = [category0, category1, category2];
            const res = await updateSettings(token, 'visual', { data: data });
            this.props.getNewLayout();
            if (res.success) {
                this.setState({ success: true });
            }
        } catch (err) {
            console.log(err);
            this.setState({ error: "", success: false });

        }

    }
    

    handleChange = (e) => {
        e.preventDefault()

        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const { error, categories, isFetching, category0, category1, category2 } = this.state

        console.log(category0)

        return (
            <div className="visual-settings">
                <form className="form" onSubmit={this.submitHandler}>

                    <WindowSpinner background="#171717" visible={isFetching} />
                    {(error !== "") ? (<div className="box-error"> {error} </div>) : ""}
 
                    {
                        categories.slice(0, 2).map(row => {
                            return (
                                <div className="item-category">
                                    {row.name}
                                </div>
                            )

                        })
                    }
                    <div className="form-item">
                        <div className="select">
                            <label htmlFor="category0" className="label visually-hidden">Select</label>
                            <div className="select-input__wrapper">
                                <select label="category0" name="category0" id="category0" className="select-input" value={category0} onChange={this.handleChange}>
                                    <option value="" defaultValue>CUSTOM YOUR PREFERRED CATEGORY</option>
                                    {
                                        categories.slice(5).map((category, i) => {
                                            return (

                                                <option key={i} value={category._id}>{category.name}</option>

                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="select">
                            <label htmlFor="category1" className="label visually-hidden">Select</label>
                            <div className="select-input__wrapper">
                                <select label="category1" name="category1" id="category1" className="select-input" value={category1} onChange={this.handleChange}>
                                    <option value="" defaultValue>CUSTOM YOUR PREFERRED CATEGORY</option>
                                    {
                                        categories.slice(5).map((category, i) => {
                                            return (
                                                <option key={i} value={category._id}>{category.name}</option>

                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-item">
                        <div className="select">
                            <label htmlFor="category2" className="label visually-hidden">Select</label>
                            <div className="select-input__wrapper">
                                <select label="category2" name="category2" id="category2" className="select-input" value={category2} onChange={this.handleChange}>
                                    <option value="" defaultValue>CUSTOM YOUR PREFERRED CATEGORY</option>
                                    {
                                        categories.slice(4).map((category, i) => {
                                            return (
                                                <option key={i} value={category._id}>{category.name}</option>

                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="item-category">
                        {
                        categories && categories[3] ? categories[3].name : "..."
                        }
                    </div>
                    <div className="item-category">
                    {
                        categories && categories[4] ? categories[4].name : "..."
                        }
                    </div>
                    <div className="item-category">
                        ...
                    </div>

                    <button type="submit" className="btn btn--secondary"><span className="btn__label">Save preferences</span></button>
                </form>
                {
                    this.state.success && <Redirect to="/" />
                }

            </div>
        )
    }

}
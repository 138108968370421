import cx from 'classnames'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

const HeaderName = ({
  children,
  className: customClassName,
  href
}) => {

  const className = cx(`header__name`, customClassName)

  return (
    <Link to={href} className={className}>
        {children}
    </Link>
  )
}

HeaderName.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
}

export default HeaderName

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class QuickLinks extends Component {
  state = {
    item: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.state.item) {
        this.setState({ item: false });
      }
      if (this.state.item) {
        this.setState({ item: false });
      }
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    const index = e.currentTarget.dataset.index;
    console.log(e.currentTarget.dataset);
    if (index === this.state.item) {
      this.setState({ item: false });
    } else {
      this.setState({ item: index });
    }
  };

  render() {
    const items = [
      {
        name: "ACCREDITATIONS",
        type: "link",
        href: "https://broadcasters.mediapro.es/LaLigaFacilities/",
        submenu: [],
      },
      {
        name: "BOOKING",
        type: "link",
        href: "https://sports.overon.es/",
        submenu: [],
      },
      {
        name: "WORLD FEED",
        type: "dropdown",
        href: false,
        submenu: [
          {
            name: "CALENDAR",
            type: "ext",
            href: "https://www.laliga.com/en-ES/laliga-easports/calendar",
          },
          {
            name: "ELEMENTS, GRAPHICS, LOGOS",
            type: "app",
            href: "/explorer/world-feed-elements-grapics-and-logos",
          },
          {
            name: "OBLIGATION ELEMENTS",
            type: "app",
            href: "/explorer/broadcasts-obligation-elements",
          },
        ],
      },
      {
        name: "CONTENT",
        type: "dropdown",
        href: false,
        submenu: [
          {
            name: "STARTER PACK AND PROMO MATERIAL",
            type: "app",
            href: "/explorer/starter-pack-and-promotional-materials",
          },
          {
            name: "VIDEODAY MATERIALS",
            type: "app",
            href: "/explorer/videoday-materials",
          },
          {
            name: "PHOTOS LALIGA EA",
            type: "app",
            href: "/photos/laliga-santander",
          },
          {
            name: "PHOTOS LALIGA HYPERMOTION",
            type: "app",
            href: "/photos/laliga-smartbank",
          },
        ],
      },
      {
        name: "MUSIC",
        type: "dropdown",
        href: false,
        submenu: [
          {
            name: "CUE SHEETS",
            type: "app",
            href: "/explorer/music-cue-sheet",
          },
          {
            name: "OFFICIAL ANTHEM",
            type: "app",
            href: "/explorer/laliga-official-anthem",
          },
        ],
      },
      {
        name:"PHONETIC GUIDE",
        type: "link",
        href: "https://laliganews.es/phonetic",
        submenu:[]

      }
    ];

    return (
      <div>
        <div className="quick-links-nav">
          {items.map((item, i) => {
            return item.type === "dropdown" ? (
              <button
                className="quick-link-dropdown"
                key={i}
                data-index={i}
                onClick={this.handleClick}
              >
                {item.name}
              </button>
            ) : (
              <a
                className="quick-link-dropdown"
                target="_blank"
                href={item.href}
              >
                {item.name}
              </a>
            );
          })}
        </div>
        <div
          className={
            this.state.item ? "quick-links-new active" : "quick-links-new"
          }
        >
          <div className="grid">
            {this.state.item && (
              <h3 className="quick-links__title">
                <img style={{ maxWidth: "70px" }} src="/img/laliga-h-w.png" />{" "}
                {items[this.state.item].name}
              </h3>
            )}

            <div className="quick-links__block">
              {this.state.item &&
                items[this.state.item].submenu.map((submenu, i) => {
                  return submenu.type === "ext" ? (
                    <a href={submenu.href} className="card">
                      <img
                        src="/img/logo.svg"
                        alt="Spinner"
                        className="card__icon"
                      />
                      <h4 className="card__title">{submenu.name}</h4>
                    </a>
                  ) : (
                    <Link to={submenu.href} className="card">
                      <img
                        src="/img/logo.svg"
                        alt="Spinner"
                        className="card__icon"
                      />
                      <h4 className="card__title">{submenu.name}</h4>
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(QuickLinks);

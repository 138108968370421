
import moment from 'moment';

 const apiClient = async (method = 'GET', apiUrl = 'http://localhost:3000/api/v1', path = '/', body = {},  cache = false) => {
//export const apiClient = async (method = 'GET', path = '/', body = {}, apiUrl = 'http://localhost:3000/api/v3', cache = false) => {
    if (cache) {
        const storage = JSON.parse(localStorage.getItem(path));
        if (storage && storage.hasOwnProperty('data')) {
            if (storage.ttl > moment().unix()) {
                return storage.data;
            }
        }
    }
    
    let options = {
        method: method,
        headers: {
            'Content-Type': 'application/json'
        }
    };


    if (Object.keys(body).length > 0) {
        options.body = JSON.stringify(body);
    }


    let response = await fetch(apiUrl + path, options);
    if (response.ok) {
        const data = await response.json();
        if (cache && Number.isInteger(cache)) {
            localStorage.setItem(path, JSON.stringify({
                data: data,
                ttl: moment().unix() + cache
            }));
        }
        return data
    }

    throw new Error(response.status)

};

export default apiClient;

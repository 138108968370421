import apiClient from "./apiClient"


const getLayout = async(token, key) => {
    try {   
        const time = new Date().getTime()

        const data = await apiClient('POST', process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION, '/layout?token=' + token + '&time=' + time, {type: key});
        return data;
    }catch(err){
        throw new Error(err);
    }

}

export default getLayout;
import React, { Component } from 'react'
import getSettings from '../../utils/getSettings'
import WindowSpinner from '../WindowSpinner/WindowSpinner'
import Button from '../button/Button'
import updateSettings from '../../utils/updateSettings'
import {Redirect } from 'react-router-dom'

export default class Notifications extends Component {

    state = {
        error: false,
        notifications: [],
        isFetching: true,
        success: false
    }

    async componentDidMount() {
        try {
            const notifications = await getSettings(this.props.token, 'notifications')
            this.setState({ isFetching: false, notifications })
        } catch (err) {
            this.setState({ error: "Service is temporarily unavailable, please try again later" })
        }

    }

    handleClick = (e) => {
        e.preventDefault()
        let { notifications } = this.state
        const index = e.currentTarget.dataset.index
        const selected = notifications[index].selected

        notifications[index].selected = !selected;

        this.setState(notifications)
    }

    submitHandler = async (e) => {
        e.preventDefault()
        try {
            const data = [];
            const { token } = this.props
            const { notifications } = this.state

            for (const notification of notifications) {
                if (notification.selected) {
                    data.push(notification._id)
                }
            }

            const res = await updateSettings(token, 'notifications', { data: data });

            if (res.success) {
                this.setState({ success: true });
            }

        } catch (err) {
            console.log(err);
            this.setState({ error: "", success: false });

        }
    }


    render() {
        const { error, notifications, isFetching } = this.state
        return (
            <div className="notifications-settings">
                <WindowSpinner background="#171717" visible={isFetching} />
                {(error !== "") ? (<div className="box-error"> {error} </div>) : ""}
                {
                    notifications.map((notification, i) => {
                        const { selected, name } = notification
                        return (
                            <Button
                                key={i}
                                dataIndex={i}
                                onClick={this.handleClick}
                                variant={selected ? "primary" : "secondary"}
                                ghost={selected ? false : true}
                                icon={selected ? true : false}
                                iconType='campana'
                            >
                                {name}
                            </Button>
                        )
                    })
                }
                <Button onClick={this.submitHandler} variant="secondary">
                    Save preferences
                </Button>
                {
                    this.state.success && <Redirect to="/" />
                }
            </div>
        )
    }

}
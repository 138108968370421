
import React, { useState } from 'react';
import cx from 'classnames'
import PropTypes from 'prop-types'
import Button from '../button/Button';


const Modal = ({
    className: customClassName,
    children,
    closeModal,

}) => {
    const className = cx(`modal is-visible`, customClassName)


    return (
        <div className={className}>
            <div className="modal-container">
                <div className="modal-header">
                    <Button
                        variant="secondary"
                        size="x-small"
                        onClick={closeModal}
                        className="modal-close"
                        aria-label="Close media"
                    >
                        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="modal-close__icon"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
                    </Button>
                </div>
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    style: PropTypes.string,
    className: PropTypes.string,
}

export default Modal

import cx from 'classnames'
import PropTypes from 'prop-types'

const RowCategory = ({
    index,
    children,
    vst = 0,
    className: customClassName,
}) => {

    const className = cx(`row-category`, customClassName)


    return (
        <div className={className}>
            <div className="grid">
                {children}
            </div>
        </div>

    )
}

RowCategory.propTypes = {
    className: PropTypes.string,
    vst: PropTypes.number,
    index: PropTypes.number.isRequired
}

export default RowCategory

import React from "react";
export default function WindowSpinner({ visible, background = false }) {
  return (
    <div
      className={visible ? "spinner show" : "spinner"}
      stlye={background ? { "background-color": background } : ""}
    >
      <div className="styled__BrandsContainer-sc-u6waiu-2 dnBIZh">
        <div className="css-0">
          <div
            color="#E32D38"
            size="20"
            className="styled__LoaderBall-sc-1vfo2jd-0 hqtjRu"
          ></div>
          <div
            color="#E32D38"
            size="20"
            className="styled__LoaderBall-sc-1vfo2jd-0 hqrKjr"
          ></div>
          <div
            color="#E32D38"
            size="20"
            className="styled__LoaderBall-sc-1vfo2jd-0 hqsiaM"
          ></div>
        </div>
      </div>{" "}
    </div>
  );
}

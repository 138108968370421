import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Portal } from 'react-portal'
import cx from 'classnames'
import PropTypes from 'prop-types'
import CategoryBadge from './CategoryBadge'
import moment from 'moment'
import Modal from '../modal/Modal'
import DetailVideo from '../DetailVideo/DetailVideo'

const PortraitCard = ({
    onHover,
    _id,
    thumbnail,
    subcatId,
    token,
    className: customClassName,
    ...rest
}) => {
    const [modal, setModal] = useState(false)


    const closeModal = e => {
        e.preventDefault()
        setModal(false)
        document.body.classList.remove('body--with-modal-open');

    }

    const openModal = async e => {
        e.preventDefault()
        setModal(true)
        document.body.classList.add('body--with-modal-open');
    }

    useEffect(() => {
        // returned function will be called on component unmount 
        return () => {
            document.body.classList.remove('body--with-modal-open');
        }
    }, [])

    const className = cx(customClassName, {
        'slide-card': true,
        'slide-card--video': true

    })

    const date = rest.video && rest.video.publishAt ? moment(rest.video.publishAt).format('dddd DD/MM/YYYY') : null

    return (
      
        <div className={className}>
            <Link style={{ width: "100%" }} to={`/search?type=videos&subcategory=${subcatId}`}>
                <div className="slide-card__thumbnail">
                    <img className="slide-card__image" src={process.env.REACT_APP_THUMBNAIL_URL + thumbnail} alt="" />
                </div>
            </Link>

            <div className="slide-card__panel">
                <div className="last-video">
                    <div className="last-video__thumbnail">
                        <img src={process.env.REACT_APP_THUMBNAIL_URL + rest.video.thumbnail} alt="" />
                    </div>
                    <div onClick={openModal} className="last-video__info">
                        <h5 className="slide-card__date">
                            {date}
                        </h5>
                        <h5 className="slide-card__title">
                            {rest.video.title}
                        </h5>

                    </div>

                </div>
                <div className="slide-card__footer">
                    <Link to={`/search?type=videos&subcategory=${subcatId}`}>
                        VIEW ALL VIDEOS
                    </Link>
                </div>


            </div>   {
                modal &&
                <Portal>
                    <Modal className="modal--video" closeModal={closeModal} >
                        <DetailVideo token={token} videoId={rest.video._id} />
                    </Modal>
                </Portal>

            }
        </div>
    )
}

PortraitCard.propTypes = {

}

export default PortraitCard
